import { Center, Text } from '@chakra-ui/react'
import {
  AssessmentsList,
  SignInContent,
  useAuth,
  usePracticeAccess,
} from '@hb/shared-frontend'
import React from 'react'

export const PracticeAssessmentsView = () => {
  const { loading, authUser } = useAuth()
  const { selectedPracticeId } = usePracticeAccess()

  if (!authUser) {
    return (
      <Center w='400px' maxW='100vw' bg='white' boxShadow='md' borderRadius={6}>
        <SignInContent />
      </Center>
    )
  }
  if (loading && !selectedPracticeId) {
    return (
      <Center w='100%'>
        <Text>Loading...</Text>
      </Center>
    )
  }
  if (!selectedPracticeId) {
    return (
      <Center maxW='500px' bg='gray.50' p={2} borderRadius={6} boxShadow='md' w='100%'>
        <Text color='gray.600' px={2}>
          You do not have access to any practices
        </Text>
      </Center>
    )
  }
  return (
    <div>
      <AssessmentsList midwifeId={selectedPracticeId} />
    </div>
  )
}
