import { adminRoles } from '@hb/shared/constants'
import { useAuth } from '../../../store'

export const usePracticeRole = (practiceId: string) => {
  const claims = useAuth((s) => s.claims)
  return claims?.practiceAccess?.[practiceId]
}

export const useIsPracticeAdmin = (practiceId: string) => {
  const claims = useAuth((s) => s.claims)
  const role = claims?.practiceAccess?.[practiceId]
  return !!role && adminRoles.includes(role)
}

export const useIsPracticeSuperAdmin = (practiceId: string) => {
  const claims = useAuth((s) => s.claims)
  const role = claims?.practiceAccess?.[practiceId]
  return role === 'super-admin'
}
