import { usePracticeAccess } from '@hb/shared-frontend'
import { PracticeInvoices } from '@hb/shared-frontend/components/Invoices/PracticeInvoices'
import { SelectPracticeView } from '@hb/shared-frontend/components/Practices/SelectPracticeView'
import React from 'react'

export const PracticeInvoicesView = () => {
  const { selectedPracticeId } = usePracticeAccess()
  if (!selectedPracticeId) {
    return <SelectPracticeView />
  }
  return <PracticeInvoices />
}
