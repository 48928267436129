import {
  DataColumn,
  DataColumns,
  ListDivider,
  ListDividerItem,
  ListDividerType,
  WithId,
} from '@hb/shared/types'
import { castDate, getMonthString } from '@hb/shared/utils'

export const getFlexColumnWidth = <T extends WithId>({
  width,
  columns,
}: {
  width: number
  columns: DataColumns<T, string>
}) => {
  const { widthColumns, flexColumns } = Object.values(columns).reduce(
    (acc, curr) => {
      if (curr.width) {
        acc.widthColumns.push(curr as DataColumn<any, any> & { width: number })
      } else {
        acc.flexColumns.push(curr)
      }
      return acc
    },
    { widthColumns: [], flexColumns: [] } as {
      widthColumns: Array<DataColumn<any, any> & { width: number }>
      flexColumns: DataColumn<any, any>[]
    },
  )
  const widthColumnsWidth = widthColumns.reduce(
    (acc, curr) => acc + curr.width,
    0,
  )

  // enforce a width of at least 100 for flex columns
  return Math.max(100, (width - widthColumnsWidth) / flexColumns.length)
}

// fill in missing month keys between two month keys (exclusive)
// eg: ['2021-01', '2021-03'] => ['2021-01', '2021-02', '2021-03']
const fillMissingMonthKeys = (
  monthKeys: Array<string>,
  sortDir: 'asc' | 'desc',
) => {
  if (!monthKeys.length) return []
  if (monthKeys.length === 1) return monthKeys
  const first = sortDir === 'desc' ? monthKeys[0] : monthKeys[monthKeys.length - 1]
  const last = sortDir === 'desc' ? monthKeys[monthKeys.length - 1] : monthKeys[0]
  const [firstYear, firstMonth] = first.split('-').map(Number)
  const [lastYear, lastMonth] = last.split('-').map(Number)
  const months = []
  for (let year = firstYear; year <= lastYear; year += 1) {
    const startMonth = year === firstYear ? firstMonth + 1 : 1
    const endMonth = year === lastYear ? lastMonth : 12
    for (let month = startMonth; month <= endMonth; month += 1) {
      let m = month
      let y = year

      if (sortDir === 'asc') {
        if (m === 1) {
          m = 12
          y -= 1
        } else m -= 1
      }
      months.push(`${y}-${String(m).padStart(2, '0')}`)
    }
  }
  return sortDir === 'desc' ? months : months.reverse()
}

const getMonthKeyLabel = (monthKey: string) => {
  const [year, month] = monthKey.split('-')
  return getMonthString(new Date(`${year}-${month}-01`))
}

const getDividerItems: Record<
  ListDividerType,
  (
    divider: ListDivider,
    items: WithId<any>[],
    sortDirection: 'asc' | 'desc'
  ) => Array<WithId<any> | ListDividerItem>
> = {
  month: (divider, items, sortDir) => {
    // ex: { '2021-01': 'January 2021' }
    const existingMonthKeys: Array<string> = []

    return items.reduce(
      (acc, curr, i) => {
        if (i === 0) {
          const itemDate = curr[divider.propPath]
          if (!itemDate) return [{ _type: 'divider', label: 'No date' }, curr]
          const asDate = castDate(itemDate)
          const monthString = getMonthString(asDate)
          const monthKeyString = asDate.toISOString().slice(0, 7)
          existingMonthKeys.push(monthKeyString)
          return [{ _type: 'divider', label: monthString }, curr]
        }

        // if previous data item has a different month string, add dividers for missing months
        const prevItemDate = items[i - 1][divider.propPath]
        const currItemDate = curr[divider.propPath]
        const prevDate = prevItemDate ? castDate(prevItemDate) : null
        const currDate = currItemDate ? castDate(currItemDate) : null
        if (!currItemDate || !currDate || Number.isNaN(currDate)) return [...acc, curr]
        const currMonthKeyString = currDate.toISOString().slice(0, 7)
        const prevMonthKeyString = prevDate
          ? prevDate.toISOString().slice(0, 7)
          : null
        if (currMonthKeyString === prevMonthKeyString) return [...acc, curr]

        const monthKeys = fillMissingMonthKeys(
          existingMonthKeys.length
            ? [
              existingMonthKeys[existingMonthKeys.length - 1],
              currMonthKeyString,
            ]
            : [currMonthKeyString],
          sortDir,
        )
        existingMonthKeys.push(currMonthKeyString)
        return [
          ...acc,
          ...monthKeys.map((monthKey) => ({
            _type: 'divider',
            label: getMonthKeyLabel(monthKey),
          })),
          curr,
        ]
      },
      [] as Array<WithId<any> | ListDividerItem>,
    )
  },
}

export const getItemsWithDividers = (
  items: WithId<any>[],
  columns: DataColumns<any, any>,
  sortKey: string,
  sortDirection: 'asc' | 'desc',
): Array<WithId<any> | ListDividerItem> => {
  const selectedColumn = Object.entries(columns).find(
    ([, c]) => c.sortKey === sortKey,
  )
  if (!selectedColumn) return items
  const [, column] = selectedColumn
  const { dividers } = column
  if (!dividers) return items
  return dividers.reduce(
    (acc, divider) => getDividerItems[divider.type](
      divider,
      acc,
      sortDirection,
    ),
    items as Array<WithId<any> | ListDividerItem>,
  )
}
