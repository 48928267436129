import { Flex, Text } from '@chakra-ui/react'
import { ListDividerItem } from '@hb/shared/types'
import React, { CSSProperties } from 'react'

export const ListDividerView = ({ item, style, mobileLayout }: {item: ListDividerItem, style?: CSSProperties, mobileLayout?: boolean}) => (
        <Flex
          align='center'
          bg="gray.50"
          position="relative"
          borderTop="1px solid #cdcdcd"
          borderBottom="1px solid #cdcdcd"
          left={0}
          justify={mobileLayout ? 'center' : 'space-between'}
          height="100%"
          width="100%"
          px={3}
          style={style}
        >
          {
            mobileLayout ? null : (
          <Text as='span' fontSize='sm' fontWeight={600} color="#8b8b8b">
            {item.label.toUpperCase()}
          </Text>
            )
          }
          <Text as='span' fontSize='sm' fontWeight={600} color="#8b8b8b">
            {item.label.toUpperCase()}
          </Text>
          {
            mobileLayout ? null : (
          <Text as='span' fontSize='sm' fontWeight={600} color="#8b8b8b">
            {item.label.toUpperCase()}
          </Text>
            )
          }
        </Flex>
)
