import { Popover, PopoverTrigger } from '@chakra-ui/react'
import { doc } from 'firebase/firestore'
import React, { useContext, useMemo } from 'react'
import { db } from '../../../../backend/db'
import { DataListContext } from '../../../../contexts/DataListContext'
import { DataGridContext } from '../../../../contexts/UserGridContext'
import { GridItemPreviewContent } from '../../../DataView/GridItemPreviewContent'
import { UrgentPopoverContent } from '../../../Users/Profile/UrgentPopover'
import { SortColorCircle } from '../../../Users/UrgentBadge'
import { SortButton } from '../../SortButton'

export const ListUrgentPopover = ({
  sortKey = 'urgentSort',
}: {
  sortKey?: string
}) => {
  const {
    closePreview, item, preview, collection,
  } = useContext(DataGridContext)
  const { sortBy } = useContext(DataListContext)
  const { id } = preview || {}
  // const { urgentColor = colors.red.hex } = getIsUrgent(item) || {}
  const docId = useMemo(() => (collection === 'claims' ? item?.assessmentId : id), [collection, id, item])
  const updateRef = useMemo(
    () => (docId
      ? doc(
        db,
        ['assessment-snippets', 'claims'].includes(collection)
          ? 'assessments-admin'
          : collection,
        docId,
      )
      : null),
    [collection, docId],
  )
  return (
    <Popover
      onClose={closePreview}
      closeOnBlur={false}
      isOpen={!!item}
      isLazy
      trigger='click'
      placement='right'
    >
      <PopoverTrigger>
        <SortButton noArrow sortKey={sortKey}>
          <SortColorCircle
            onClick={() => sortBy('urgentSort')}
            mx='auto'
            // urgent
          />
        </SortButton>
      </PopoverTrigger>
      <GridItemPreviewContent previewId='urgent'>
        <UrgentPopoverContent item={item} updateRef={updateRef} />
      </GridItemPreviewContent>
    </Popover>
  )
}
