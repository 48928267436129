import {
  adminAccess, superAdminAccess, userAccess, View,
} from '@hb/shared'
import { AdminsView, ContactView } from '@hb/shared-frontend'
import { PracticeAssessmentsView } from './Views/Assessments'
import { ConsentFormsView } from './Views/ConsentForms'
import { PracticeInvoicesView } from './Views/Invoices'
import { PracticeInfoView } from './Views/Practice/PracticeInfoView'
import { SettingsView } from './Views/Settings/SettingsView'
import { SignInPage } from './Views/SignInPage'

const headerDashboardView: View = {
  name: 'Dashboard',
  path: [
    '/assessments',
    '/assessments/:assessmentId',
    '/assessments/:assessmentId/:displayedItem',
    '/patients',
    '/patients/:patientId',
    '/patients/:patientId/:displayedItem',
    '/patients/:patientId/:assessmentId/:displayedItem',
  ],
  exact: true,
  component: PracticeAssessmentsView,
  access: adminAccess,
  onMenu: true,
}

const signInView: View = {
  name: 'Sign In',
  path: [
    '/',
    '/sign-in',
  ],
  exact: true,
  component: SignInPage,
}

const contactView: View = {
  name: 'Contact',
  path: '/contact',
  component: ContactView,
  onMenu: true,
}

const practiceInfoView: View = {
  name: 'Practice Info',
  path: ['/practice-info', '/practice-info/:displayedItem'],
  component: PracticeInfoView,
  access: adminAccess,
  onMenu: true,
  signIn: true,
}

const adminsView: View = {
  name: 'Admins',
  path: ['/admins', '/admins/:adminId', '/admins/:adminId/:displayedItem'],
  component: AdminsView,
  access: adminAccess,
  signIn: true,
  onMenu: true,
}

const consentFormsView: View = {
  name: 'Consent Forms',
  path: '/consent-forms',
  component: ConsentFormsView,
  access: superAdminAccess,
  onMenu: true,
}
const invoicingTabs = [
  'outstanding',
  'paid',
]

const invoicesView: View = {
  name: 'Invoices',
  path: [
    '/super-admin/invoices',
    ...invoicingTabs
      .map((tab) => [
        `/super-admin/invoices/${tab}`,
        `/super-admin/invoices/${tab}/:invoiceId`,
        `/super-admin/invoices/${tab}/:invoiceId/assessment/:assessmentId`,
        `/super-admin/invoices/${tab}/:invoiceId/assessment/:assessmentId/:assessmentTab`,
      ])
      .flat(),
  ],
  access: superAdminAccess,
  onMenu: true,
  component: PracticeInvoicesView,

}

const settingsView: View = {
  name: 'Settings',
  path: '/settings',
  component: SettingsView,
  access: userAccess,
  onMenu: true,
}

export const views: View[] = [
  signInView,
  headerDashboardView,
  contactView,
  practiceInfoView,
  adminsView,
  consentFormsView,
  settingsView,
  invoicesView,
]
