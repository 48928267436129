import {
  Button, Center, Flex, Text,
} from '@chakra-ui/react'
import {
  DataColumn,
  DataColumns,
  formatPhoneNumber,
  getReverseName, UserRoleItem,
  UserSortKey,
} from '@hb/shared'
import React from 'react'
import { Link } from 'react-router-dom'
import { SortColorCircle } from '../../../Users'
import { SortButton } from '../../SortButton'
import { ListSortColorPopover } from './ListSortColorPopover'

const adminColorColumn: DataColumn<UserRoleItem, UserSortKey> = {
  title: 'Color',
  Header: () => (
    <Flex px={1} py="2px">
      <ListSortColorPopover sortKey='adminColor' />
    </Flex>
  ),
  sortKey: 'adminColor',
  width: 44,
  Render: ({ data, preview: { openPreview }, cell }) => {
    const { adminColor, id } = (data) || {}
    return (
      <Center w="100%" flex={1}>
        <Center px={2}>
          <SortColorCircle
            onClick={(e) => openPreview({
              id,
              item: 'sortColor',
              position: { x: e.clientX, y: e.clientY },
              cell,
            })
            }
            withAdmins
            px={1}
            color={adminColor || undefined}
          />
        </Center>
      </Center>
    )
  },
}

const nameColumn: DataColumn<UserRoleItem, UserSortKey> = {
  title: 'Name',
  Header: () => (
    <Flex align="center" pl={2}>
      <SortButton sortKey="lname">NAME</SortButton>
    </Flex>
  ),
  width: 215,
  sortKey: 'lname',
  Render: ({ data: user, app: { appName } }) => (
    <Flex mb={1}>
      <Link
        style={{ opacity: 1 }}
        to={`${appName === 'app' ? '/admin' : ''}/${
          ['admin', 'super-admin'].includes(user.role) ? 'admins' : 'patients'
        }/${user.id}`}
      >
        <Button
          fontWeight={500}
          justifyContent="flex-start"
          fontSize="md"
          variant="link"
        >
          <Text
            textAlign="left"
            w="200px"
            pl={2}
            color="blackAlpha.800"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {getReverseName(user)}
          </Text>
        </Button>
      </Link>
    </Flex>
  ),
}

const emailColumn: DataColumn<UserRoleItem, UserSortKey> = {
  title: 'Email',
  Header: () => (
    <Flex align="center">
      <SortButton sortKey="email">EMAIL</SortButton>
    </Flex>
  ),
  width: 215,
  sortKey: 'email',
  Render: ({ data: user }) => (
    <Text textAlign="left" isTruncated color="blackAlpha.800">
      {user.email || 'None'}
    </Text>
  ),
}

const phoneColumn: DataColumn<UserRoleItem, UserSortKey> = {
  title: 'Phone',
  Header: () => <Text>PHONE</Text>,
  Render: ({ data: user }) => (
    <Text textAlign="left" isTruncated color="blackAlpha.800">
      {formatPhoneNumber(user?.phone)}
    </Text>
  ),
}

// const calendlyLinkColumn: DataColumn<WithId<AdminAdminData>, UserSortKey> = {
//   Header: () => <Text>CALENDLY LINK</Text>,
//   Render: ({ data: user }) => {
//     let text = ''
//     let color = '#888'
//     if (user.calendlyLink && user.calendlyDescription) {
//       text = user.calendlyDescription
//       color = '#333'
//     } else if (user.calendlyLink) {
//       text = 'Missing description'
//       color = 'orange.600'
//     } else {
//       text = 'None'
//     }
//     return (
//       <Text color={color} textAlign='left' isTruncated >
//         {text}
//       </Text>
//     )
//   },
// }

// const roleColumn: DataColumn<UserRoleItem> = {
//   header: () => (
//     <Flex align='center'>
//     <SortButton sortKey='role'>ROLE</SortButton>
//   </Flex>
//   ),
//   render: ({ data: user }) => (
//       <Text textAlign='left' isTruncated color='blackAlpha.800'>
//         {formatPhoneNumber(user?.role) || 'None'}
//       </Text>
//   ),
// }

export const userColumns: DataColumns<UserRoleItem, UserSortKey> = {
  name: nameColumn,
  email: emailColumn,
  phone: phoneColumn,
  // role: roleColumn,
  // // urgent: assessmentUrgentColumn,
  // redFlags: redFlagsColumn,
}

export const adminColumns: DataColumns<UserRoleItem, UserSortKey> = {
  color: adminColorColumn,
  name: nameColumn,
  email: emailColumn,
  phone: phoneColumn,
  // calendly,
  // role: roleColumn,
  // // urgent: assessmentUrgentColumn,
  // redFlags: redFlagsColumn,
}
