import { Box, Center } from '@chakra-ui/react'
import { PageContainer, SignInContent, useAuth } from '@hb/shared-frontend'
import React from 'react'
import { SettingsView } from './Settings/SettingsView'

export const SignInPage = () => {
  const authUser = useAuth((s) => s.authUser)
  return (
  <PageContainer>
    <Center w="100%">
      {
        authUser ? (
          <SettingsView/>
        ) : (

      <Box w='100%' maxW='500px' bg="white">
        <SignInContent />
      </Box>
        )
      }
    </Center>
  </PageContainer>
  )
}
