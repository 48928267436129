import {
  Button, Center, Flex, Text, VStack,
} from '@chakra-ui/react'
import { colors } from '@hb/shared'
import {
  FloatingContainer,
  PageContainer,
  PracticeConsentFormsView,
  usePracticeAccess,
} from '@hb/shared-frontend'
import React from 'react'
import { Link } from 'react-router-dom'

export const ConsentFormsView = () => {
  const { selectedPracticeId } = usePracticeAccess()

  if (!selectedPracticeId) {
    return (
      <PageContainer>
        <Center w="100%">
          <FloatingContainer w="auto">
            <VStack spacing={0}>
              <Text fontFamily="Open Sans">No practice selected</Text>
              <Flex gap="0.3rem">
                <Text>Go to</Text>
                <Link to="/settings">
                  <Button color={colors.green.hex} variant="link">
                    Settings
                  </Button>
                </Link>
                <Text>to select a practice</Text>
              </Flex>
            </VStack>
          </FloatingContainer>
        </Center>
      </PageContainer>
    )
  }
  return (
    <PageContainer>
      <PracticeConsentFormsView practiceId={selectedPracticeId} />
    </PageContainer>
  )
}
