import { Flex, Text, VStack } from '@chakra-ui/react'
import { PageContainer, PracticeView, usePracticeAccess } from '@hb/shared-frontend'
import React from 'react'
import { Link } from 'react-router-dom'

export const PracticeInfoView = () => {
  const { selectedPracticeId } = usePracticeAccess()

  if (!selectedPracticeId) {
    return (
      <VStack>
        <Text>
          No practice selected. If you are part of a practice, go to{' '}
          <Link to="/settings">Settings</Link> to select it
        </Text>
      </VStack>
    )
  }

  return (
    <PageContainer>
      <Flex borderRadius={6} boxShadow='1px 1px 4px #00000077' w='100%' p={3} bg='white' flexFlow="column">
        <PracticeView id={selectedPracticeId} />
      </Flex>
    </PageContainer>
  )
}
