import {
  type Descendant,
  type EditorDraft,
  type EditorVersion,
} from '../../editor/types'
import {
  ChargedPaymentReceived,
  Invoice,
  PracticeInvoiceOmission,
} from '../../invoicing/types/invoice'
import { Appointment } from '../appointments'
import { PatientAuthorization } from '../authorizations'
import { type SubmitNewClaimArgs } from '../claims'
import { type ClaimSnippet } from '../claims/claim'
import { FileDBValue } from '../data'
import { InsuranceProvider } from '../insurance'
import {
  AssessmentPaymentsData,
  PaymentDue,
  PaymentReceived,
} from '../payments'
import { Alert } from '../shared/alert'
import {
  DBMetadata,
  UserGroup,
  WithMetadata,
  WithPartialMetadata,
} from '../shared/db'
import { TemplateKey } from '../templates'
import {
  Address,
  AssessmentStatus,
  FieldMapValue,
  Log,
  NextAction,
  Note,
  RedFlagRecord,
  WithId,
} from '../utils'
import { AssessmentStages } from './shared'

export type PracticeAssessmentStage =
  | 'Inquiry'
  | 'Current'
  | 'Postpartum'
  | 'Gyn'
  | 'Complete'
  | 'Other'
  | 'Discharged'
export type PracticeAssessmentStages = Array<PracticeAssessmentStage>

export interface FaxRecord {
  sentOn: number
  sentBy: string
  text: any[]
}

export type ReferenceNumberData = {
  referenceNumber?: string
  notes?: string
  updatedOn?: number
  updatedBy?: string
}

export interface AdminAssessmentData {
  // useManualPayments?: boolean
  // manualPayments?: LegacyPaymentsData<any>
  // patientPrm?: number
  authorizations?: Record<string, PatientAuthorization>
  // claims?: Record<string, Claim>
  log?: Log
  paymentsNote?: Note
  alerts?: Record<number, string | Alert>
  urgentReason?: string
  urgentColor?: string
  claimsDrafts?: Record<
    string,
    { name: string; data: Partial<SubmitNewClaimArgs> }
  >
  financialAdj?: number
  balanceTowardFee?: number
  nextAction?: NextAction
  files?: Record<string, FileDBValue>
  miscarried?: boolean
  miscarriedUpdatedOn?: number
  miscarriedUpdatedBy?: string
  miscarriedUpdatedByGroup?: 'patient' | 'practice' | 'admin'
  patientPrmNotes?: string
  authNote?: Note
  authReferenceNumbers?: Array<ReferenceNumberData>
  claimsReferenceNumbers?: Array<ReferenceNumberData>
  claimsNote?: Note
  assessmentNote?: Note
  redFlags?: RedFlagRecord
  payments?: AssessmentPaymentsData
  faxRecords?: Record<string, Array<FaxRecord>>
  hasOverduePayment?: boolean
  draft?: Descendant[]
  draftEditorVersion?: EditorVersion
  stages: AssessmentStages
  // other drafts (clinicals, receipts, etc)
  drafts?: Record<string, Descendant[] | EditorDraft>
  draftSavedOn?: number
  draftSavedBy?: string
  forceUpdatedOn?: number
}

export type PracticeAssessmentData = Pick<
  AdminAssessmentData,
  | 'balanceTowardFee'
  | 'files'
  | 'nextAction'
  | 'alerts'
  | 'miscarried'
  | 'assessmentNote'
  | 'miscarriedUpdatedBy'
  | 'miscarriedUpdatedByGroup'
  | 'miscarriedUpdatedOn'
  | 'financialAdj'
  | 'hasOverduePayment'
> &
  Pick<
    AssessmentSnippet,
    | 'patientId'
    | 'assessmentName'
    | 'balanceTowardPrm'
    | 'dob'
    | 'nickname'
    | 'markedCompleteOn'
    | 'signedOnDate'
    | 'email'
    | 'hasOverduePayment'
    | 'phone'
    | 'partnerName'
    | 'fname'
    | 'unreadThreadMessages'
    | 'hasComplaints'
    | 'planCoverageType'
    | 'planState'
    | 'isMarketplacePlan'
    | 'isMedicaidPlan'
    | 'lname'
    | 'assessmentName'
    | 'clearedOn'
    | 'clearedBy'
    | 'partnerEmail'
    | 'nextActionDate'
    | 'insuranceCoverage'
    | 'authRefNumbers'
    | 'stringified'
    | 'archived'
    | 'edd'
    | 'prm'
    | 'deliveredOn'
    | 'status'
    | 'midwifeName'
    | 'createdOn'
  > & {
    midwifeId: string
    sortColor: string
    stages: PracticeAssessmentStages
    nextPatientAppointment: WithId<Appointment> | null
    nextPatientAppointmentDate: number | null
    archivedOn: number | null
    payments?: AssessmentPaymentsData
    delivery?: AssessmentSnippet['delivery'] | null

  }

export interface AssessmentInvoiceSnippet {
  manuallyAddedBy: string | null
  invoiceId: string
  practiceId: string
  invoiceNumber: number | null
  sentOn: string | null
  sentBy: string | null
  chargedPayments: Record<string, ChargedPaymentReceived>
  invoiceStatus: Invoice['status']
  manualPaymentIds: Array<string> | null
  omissions: Record<string, PracticeInvoiceOmission>
  practiceName: string
  scheduledFor: string | null
  payment: {
    amount: number
    paidOn: string | null
    markedPaidBy: string | null
    paymentType: string | null
  } | null
}

export type AssessmentInvoiceSnippets = Record<string, AssessmentInvoiceSnippet>

export type DeliveryType = 'nsvd' | 'cSection' | 'breech' | 'vacuum' | 'forceps'

export interface Delivery {
  location: string
  locationOther?: string
  newbornSex: string
  deliveryTypes?: Array<DeliveryType>
  newbornFname: string | null
  newbornLname: string | null
  newbornWeight: number | null
  summary?: string
  isTransfer?: boolean
}

export interface AssessmentSnippet {
  // derived from patient
  fname: string
  lname: string
  email: string
  phone: string

  stringified: string
  hasOverduePayment: boolean

  // derived from assessment
  patientId: string
  assessmentName?: string
  nickname?: string
  createdOn: number
  midwifeName: string
  midwifeId?: string | null
  planCoverageType: string | null
  planState: string | null
  isMarketplacePlan: boolean
  isMedicaidPlan: boolean
  hasComplaints: boolean
  insuranceCoverage: string
  status: AssessmentStatus
  edd: number
  dob?: number
  signedOnDate: number | null
  additionalAuthRefNumbers?: Array<ReferenceNumberData>
  additionalClaimsRefNumbers?: Array<ReferenceNumberData>
  authRefNumbers?: Array<string>
  claims?: Record<string, ClaimSnippet>

  // directly copied from admin data
  stages: AdminAssessmentData['stages']
  deliveredOn: number
  delivery?: WithMetadata<Partial<Delivery>>
  assessmentSentOn: number | null
  urgentColor?: AdminAssessmentData['urgentColor']
  urgentReason?: AdminAssessmentData['urgentReason']

  // derived from admin data
  markedCompleteOn: number | null
  logSnippet?: Log
  archived: boolean
  nextActionText: string
  nextActionDate: number
  partnerName?: string
  partnerEmail?: string
  // urgent color if set, else red if urgent, else zzz
  urgentSort: string
  redFlagText?: string
  inquiryRank: number
  // invoiceIds: Array<string>
  duePayments: Array<WithId<PaymentDue>>
  receivedPayments: Array<WithId<PaymentReceived>>
  prm: number | null
  balanceTowardPrm: number | null
  unreadThreadMessages: number

  // cleared for coverage
  clearedOn: number | null
  clearedBy: string | null

  signedOnByPractice?: number | null

    // TODO: remove
    clearedOnInitializedOn?: number
}

export interface AssessmentDocumentVersion {
  text: Descendant[]
  editorVersion?: EditorVersion
  sentOn: number
  sentBy: string
  viewedOn?: number | null
}

export interface ConsentFormVersion extends AssessmentDocumentVersion {
  formData: FieldMapValue | null
  toBeSignedByUser: string
  signedOn: number | null
  signedBy: string | null
  signedStoragePath: string | null
}

export type ReimbursementOption = 'medicare-rates' | 'r-and-c' | 'unknown'
export type PlanDesignOption = 'fully-insured' | 'split-funded' | 'self-funded'
export type PolicyOwner = {
  policyOwnerName?: {
    fname: string
    lname: string
  },
  policyOwnerSex?: string
  policyOwnerDob?: number
  policyOwnerSameAddress?: boolean
  policyOwnerAddress?: Address,
  policyOwnerPhone?: string
  policyOwnerEmail?: string
}

export type SentEmailData = {on: number, by: string, message: string}
export type CallInRequest = { on: number; by: string; message: string, emailsSent: Array<SentEmailData> }

export interface InsuranceCoverageRequest {
  type: 'request'
  withCallInForm: boolean
  requestedOn: number
  requestedBy: string
  message: string
  emailsSent: Array<SentEmailData>
}

export type InsuranceCoverageUpdateType = 'basicInfo' | 'callIn' | 'policyOwner' | 'unsetAsPrimary' | 'setAsPrimary'
export type InsuranceCoverageHistory = {
  type: InsuranceCoverageUpdateType
  on: number
  by: string
  notes?: string
}
export interface BaseInsuranceCoverage {
  type?: never
  label?: 'primary' | 'secondary' | null
  isMedicaid: boolean
  insuranceProviderId: string
  insuranceProvider?: InsuranceProvider
  currentlyOnMedicaidPlan?: boolean
  initialServiceDate?: string
  planName?: string
  noMemberId?: boolean
  callInRequests?: Record<string, CallInRequest>
  fromRequest?: InsuranceCoverageRequest | null
  memberId?: string
  policyOwnerRelationship?: string
  policyOwnerInfo?: PolicyOwner,
  terminationDate?: number
  insuranceProviderNumber?: string
  'in-network'?: {
    coinsurance: number
    deductible: number
    deductibleBasedOnCalendarYear: boolean
    deductibleCountsTowardOOPM: boolean
    outOfPocketMax: number
    insuranceCard: {
      front?: FileDBValue
      frontImageIncludesBack: boolean
      back?: FileDBValue
    }
    notes?: string
  }
  'out-of-network'?: {
    coinsurance: number
    deductible: number
    deductibleCountsTowardsOOPM: boolean
    outOfPocketMax: number
    maximumReimbursableCharges: {
      amount?: number
      notes?: string
    }
    noBenefits?: boolean
    reimbursementOption: {
      optionText: ReimbursementOption
      medicareRate?: number
      reasonableAndCustomaryRate?: number
      notes?: string
    }
  }
  'plan-design-and-state-mandates'?: {
    planDesign: PlanDesignOption
    followsStateMandates: boolean
    confirmFollowsStateMandates?: boolean
    confirmDoesNotFollowStateMandates?: boolean
    // state plan is based in - legacy prop name, should be changed to planState
    followsStateMandatesNote?: string
    notes?: string
  }
  'call-details'?: {
    agentName: string
    callReferenceNumber: string
    notes?: string
  }
  history?: Array<InsuranceCoverageHistory>
}

export interface InsuranceCoverage extends WithMetadata<BaseInsuranceCoverage> {}

export const isInsuranceCoverageRequest = (
  c: BaseInsuranceCoverage | InsuranceCoverageRequest,
): c is InsuranceCoverageRequest => c.type === 'request'

export interface AssessmentDocument {
  type: TemplateKey
  viewedOn?: number
  expiresOn: number
  expired: boolean
  assessmentId: string
  patientId: string
  editorVersion?: EditorVersion
  name?: string
  sentOn: number
  sentBy: string
  text: Descendant[]
  previousVersions?: AssessmentDocumentVersion[]
  assessmentArchived: boolean
}

export interface ConsentForm
  extends Omit<
    AssessmentDocument,
    'previousVersions' | 'expiresOn' | 'expired'
  > {
  type: 'consentForm'
  formData: FieldMapValue | null
  previousVersions?: ConsentFormVersion[]
  signedOn: number | null
  signedBy: string | null
  signedStoragePath: string | null
  toBeSignedByUser: string
}

export const isConsentForm = (
  doc: AssessmentDocument | ConsentForm,
): doc is ConsentForm => doc.type === 'consentForm'

export type AssessmentDocumentArgs = Omit<
  AssessmentDocument,
  'assessmentArchived' | 'previousVersions' | 'expiresOn' | 'expired'
>

export interface Assessment extends Partial<DBMetadata> {
  name?: string

  data?: WithPartialMetadata<FieldMapValue>
  corrections?: WithPartialMetadata<FieldMapValue>
  additionalPlans?: Record<string, InsuranceCoverage | InsuranceCoverageRequest>

  additionalPlansConfirmedOn?: number
  additionalPlansConfirmedBy?: string
  additionalPlansRequestedOn?: number
  additionalPlansRequestedBy?: string

  skippedQuestionnaire?: boolean
  createdOn: number
  createdBy?: string
  submittedOn?: number
  submittedBy?: string
  submittedByGroup?: 'patient' | 'practice' | 'admin'
  sentOn?: number
  sentBy?: string
  answersUpdatedOn?: number
  answersUpdatedBy?: string
  answersUpdatedByGroup?: UserGroup
  correctionsUpdatedOn?: number
  // TODO: delete (moved to snippet)
  status?: AssessmentStatus

  signOnData?: WithPartialMetadata<FieldMapValue>
  signOnCorrections?: WithPartialMetadata<FieldMapValue>
  signedOnDate?: number
  disclaimers?: {
    disclaimer1Accepted?: boolean
    disclaimer2Accepted?: boolean
  }

  correctionsUpdatedBy?: string
  correctionsUpdatedByGroup?: UserGroup
  resultsViewedOn?: number
  patientId: string
  results?: Descendant[]

  // if no version specified, assume v1
  editorVersion?: EditorVersion
  previousResults?: Array<{
    editorVersion?: EditorVersion
    results: Descendant[]
    sentOn: number | null
    sentBy: string | null
  }>

  authAppeals?: Record<string, AssessmentDocument>
  authInstructions?: Record<string, AssessmentDocument>

  archivedOn: number | null
  archivedBy?: string | null
  midwifeId?: string | null

  files?: Record<string, FileDBValue>

  inviteSentOn?: number
  inviteSentBy?: string

  forceUpdatedOn?: number
}

export type NewAssessment = Partial<Assessment>
export const isNewAssessment = (
  a: Assessment | NewAssessment,
): a is NewAssessment => !a.submittedOn

export type AssessmentSortKey =
  | keyof Pick<
      AssessmentSnippet,
      | 'email'
      | 'lname'
      | 'urgentSort'
      | 'nextActionDate'
      | 'insuranceCoverage'
      | 'deliveredOn'
      | 'signedOnDate'
      | 'midwifeName'
      | 'inquiryRank'
      | 'hasOverduePayment'
      | 'redFlagText'
      | 'edd'
      | 'unreadThreadMessages'
    >
  | 'claimNextAction'
  | keyof Pick<
      PracticeAssessmentData,
      'sortColor' | 'nextPatientAppointmentDate'
    >

export type ConfirmCoverageStage = 'primaryCoverage' | 'medicaidCoverage' | string | 'confirm'
export type CoverageSnippet = 'basic-info' | 'call-in' | 'policy-owner'
type CoverageStageIncomplete = Array<CoverageSnippet>
type CoverageStageRequired = Array<CoverageSnippet>
export type CoverageStageStatus = {
  incomplete: CoverageStageIncomplete
  required: CoverageStageRequired
} | null

export type ConfirmCoverageStatus = {
  primaryCoverage: CoverageStageStatus
  medicaidCoverage: CoverageStageStatus
  additionalPlans: Record<string, CoverageStageStatus>
}
